.user__container {
    max-width: 1000px;
    margin: 0 auto;
    float: right;
    margin-right: 100px;

}

.user__heading {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
}

.user__button {
    border: 1px solid rgba(211, 211, 211, 0.681);
    border-radius: 2px;
    padding: 8px;
    width: auto;
    margin-top: 20px;
}