.auth__container {
    max-width: 700px;
    margin: 0 auto;
    padding: 40px;
}

.auth__heading {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
}

.auth__byline
{
    font-size: 1em;
    font-weight: normal;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.auth__formDiv {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}
.auth__formHeading {
    font-weight: 500;
    text-align: left;
    padding-bottom: 10px;
}

.auth__formInput {
    border: 1px solid rgba(211, 211, 211, 0.681);
    border-radius: 2px;
    padding: 8px;
    width: auto;
    margin-top: 3px;
}

.auth__buttonNotHovered {
    border: 1px solid rgb(255, 125, 3);
    border-radius: 2px;
    padding: 8px;
    width: 100%;
    margin-top: 20px;
    /* Background */
    background: rgb(255, 125, 3);
    /* Text */
    color: white;
    font-weight: bold;
    /* text border */
    /* text-border: 1px solid rgb(255, 125, 3); */
    /* textShadowOffset:{width: 5, height: 5}, */


}

.auth__buttonHovered {
    border: 1px solid rgb(255, 125, 3);
    border-radius: 2px;
    padding: 8px;
    width: 100%;
    margin-top: 20px;
    /* Background */
    background: rgb(254, 140, 33);
    /* Text */
    color: white;
    /* Bold */
    font-weight: bold;

}

