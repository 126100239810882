.posttests__container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    /* center the container */
}

.posttests__heading {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
}

.posttests__button {
    border: 1px solid rgba(211, 211, 211, 0.681);
    border-radius: 10px;
    padding: 8px;
    width: auto;
    margin-top: 20px;
    background-color: white;
    /* shadow */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.posttests__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}