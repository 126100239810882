/* Center container in the middle of the page */
.account__container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center; /* Align text in the center */
  padding: 20px; /* Add some padding */
  background-color: #f9f9f9; /* Optional: light background color */
  box-sizing: border-box; /* Ensure padding is included in height */
}

/* Styling for the heading */
.account__heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

/* Center the button and inputs */
.button {
  background: linear-gradient(to right, #4f46e5, #3b82f6);
  color: #ffffff;

  border: none;
  border-radius: 4px;
  padding: 10px 20px;

  font-size: 16px;
  cursor: pointer;

  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out, transform 0.1s ease-out;

  margin-top: 10px;
}

.button:hover {
  background: linear-gradient(to right, #4338ca, #2563eb);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.button:active {
  transform: scale(0.97);
}

.button:disabled {
  background: #b3b3b3;
  cursor: not-allowed;
  box-shadow: none;
}

/* Input field styling */
input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  max-width: 300px; /* Optional: constrain input width */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

input[type="text"]:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

/* Add spacing between elements */
label {
  font-size: 16px;
  margin-bottom: 8px;
  color: #000;
}
