.ting__container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.ting__heading {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.ting__button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ting__button button {
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 1em;
    font-weight: bold;
    background-color: #3f51b5;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ting__button button:hover {
    background-color: #303f9f;
}
