.titles {
    /* position: absolute; */
    width: 550px;
    height: 130px;
    /* padding-right: 100px; */
    /* gap: 100px; */
    /* Space between the end of text input and the next thumbnail */
    margin-right: 20px;
}

.thumbnails {
    /* position: absolute; */
    width: 550px;
    height: 100px;
    /* padding-right: 100px; */
    gap: 10px;
    /* Space between the end of text input and the next thumbnail */
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}