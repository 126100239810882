/* wapParks.css */
.users__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .users__heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .users__list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
  }
  
  .users__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .users__list-item:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .users__list-item-button {
    padding: 10px;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .users__list-item-button:hover {
    background-color: white; 
    color: black; 
    border: 2px solid #4CAF50;
  }
  