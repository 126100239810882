.grid__container {
  padding: 20px;
}

.grid__heading {
  margin-bottom: 20px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.grid__item {
  position: relative;
  cursor: pointer;
  margin: 10px;
  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.2s;
}

.grid__item:hover {
  transform: scale(1.05);
}

.grid__image,
.grid__video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid__likes,
.grid__comments,
.grid__ratio {
  margin: 0 5px;
}

.grid__comments {
  margin-left: auto;
}
