.grid__item {
  position: relative;
  cursor: pointer;
  margin: 10px;
  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.2s;
}

.grid__item:hover {
  transform: scale(1.05);
}

.grid__image,
.grid__video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid__likes,
.grid__comments,
.grid__timeago {
  margin: 0 5px;
}

.grid__comments {
  margin-left: auto;
}

.open-new-tab-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: block;
  width: 100%;
  text-align: center;
}

.open-new-tab-button:hover {
  background-color: #0056b3;
}

.post-instagram-button {
  /* color: white; */
  /* border: none; */
  /* padding: 5px 10px; */
  /* margin: 10px 0; */
  /* border-radius: 4px; */
  /* cursor: pointer; */
  /* font-size: 14px; */
  /* width: 100%; */
  /* text-align: center; */
  /* position: absolute; */
  top: 0px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
}

.post-instagram-button:hover {
  background-color: #d85f33;
}
