* {
  margin: 0;
  flex-direction: row;
}

.app {
  background-color: #fafafa;
  height: 100vh;
}

.app__heading {
  background-color: white;
  padding: 20px;
  border-bottom: 1px solid lightgray;
  object-fit: contain;
  text-align: right;
  font-weight: bold;
  font-size: 1.5em;
}

.app__signup {
  background-color: #fafafa;
  padding: 20px;
  border-bottom: 1px solid lightgray;
  object-fit: contain;
  display: flex;
  flex-direction: column;
}

.app__calculations {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}

.app__input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
}

.app__output {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
}