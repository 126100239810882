.highlighted-parks {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.highlighted-parks h3 {
  margin-bottom: 10px;
}

.highlighted-parks ul {
  list-style: none;
  padding: 0;
}

.highlighted-parks li {
  cursor: pointer;
  padding: 5px 0;
  transition: background-color 0.3s;
}

.highlighted-parks li:hover {
  background-color: #ddd;
}

.highlighted-parks li.active {
  font-weight: bold;
}
