.filter__bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.filter__group {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.filter__label {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
  color: #333;
}

.filter__checkbox {
  margin-right: 5px;
}

.filter__input {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.date__filter {
  display: flex;
  align-items: center;
}

.date__filter button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.date__filter button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .filter__bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter__group {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .filter__input {
    margin-left: 5px;
  }
}
