.users__container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    /* center the container */
}

.users__heading {
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
}

.users__button {
    border: 1px solid rgba(211, 211, 211, 0.681);
    border-radius: 10px;
    padding: 8px;
    width: auto;
    margin-top: 20px;
    background-color: white;
    /* shadow */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgb(240, 198, 106);
    margin-bottom: 20px;
    border-radius: 5px;
    width: 100%;
}

.filter-bar input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.filter-bar button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.filter-bar button:hover {
    background-color: #0056b3;
}