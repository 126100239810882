.image-enlarger-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.prev-button:hover, .next-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

h2 {
  cursor: pointer;
  transition: color 0.3s;
}

h2:hover {
  color: #007BFF;
}

.image-enlarger-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.prev-button, .next-button, .open-new-tab-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}


.open-new-tab-button {
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  position: fixed;
  top: 10px;
}

.prev-button:hover, .next-button:hover, .open-new-tab-button:hover {
  background-color: #0056b3;
}
